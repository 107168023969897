import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ServiceCall } from './service-call.service';
import { map } from 'rxjs/operators';
import { environment } from '@environments/environment';

import {
  ICreateMigrationIntentRequest,
  ITelematicsCustomers,
  IMigrationIntent,
  IMigrationMachines,
  IMigrationLicenses,
  ICreateMigrationRequest,
  IMigration,
  IOrganizationMigrationProposal,
  IFieldBackupStateResponse,
} from '../models/migration.model';

export const MIGRATIONS_URL = 'v1/migrations';
export const MIGRATION_INTENTS_URL = 'v1/migration-intents';

@Injectable({
  providedIn: 'root',
})
export class MigrationService {
  private migrationsBaseUrl = `${environment.apiBaseUrl}/${MIGRATIONS_URL}`;
  private migrationIntensBaseUrl = `${environment.apiBaseUrl}/${MIGRATION_INTENTS_URL}`;

  constructor(private serviceCall: ServiceCall) {}

  createMigrationIntent(createMigrationIntentRequest: ICreateMigrationIntentRequest): Observable<IMigrationIntent> {
    return this.serviceCall
      .post(`${this.migrationIntensBaseUrl}`, createMigrationIntentRequest)
      .pipe(map((response) => response.data as IMigrationIntent));
  }

  getTelematicsCustomers(intentId: string): Observable<ITelematicsCustomers> {
    return this.serviceCall
      .get(`${this.migrationIntensBaseUrl}/${intentId}/telematics-customers`)
      .pipe(map((response) => response.data as ITelematicsCustomers));
  }

  getMigrationMachines(intentId: string, telematicsCustomer: number | null): Observable<IMigrationMachines> {
    return this.serviceCall
    .get(
      `${this.migrationIntensBaseUrl}/${intentId}/machines${
        telematicsCustomer != null ? `?telematicsCustomerId=${telematicsCustomer}` : ''
      }`
    )
    .pipe(map((response) => response.data as IMigrationMachines));
  }

  getMigrationLicenses(intentId: string, telematicsCustomer: number): Observable<IMigrationLicenses> {
    return this.serviceCall
      .get(`${this.migrationIntensBaseUrl}/${intentId}/licenses?telematicsCustomerId=${telematicsCustomer}`)
      .pipe(map((response) => response.data as IMigrationLicenses));
  }

  getMigrationFieldBoundaryZip(intentId: string, telematicsCustomer: number): Observable<Blob> {
    return this.serviceCall
      .getZip(`${this.migrationIntensBaseUrl}/${intentId}/fields?telematicsCustomerId=${telematicsCustomer}`)
      .pipe(map((response) => response.data as Blob));
  }

  createMigration(createMigrationRequest: ICreateMigrationRequest): Observable<IMigration> {
    return this.serviceCall
      .post(`${this.migrationsBaseUrl}`, createMigrationRequest)
      .pipe(map((response) => response.data as IMigration));
  }

  getMigrations(): Observable<IMigration[]> {
    return this.serviceCall
      .get(`${this.migrationsBaseUrl}`)
      .pipe(map((response) => response.data as IMigration[]));
  }

  getOrganizationMigrationProposal(): Observable<IOrganizationMigrationProposal> {
    return this.serviceCall
      .get(`${this.migrationsBaseUrl}/organization-migration-proposal`)
      .pipe(map((response) => response.data as IOrganizationMigrationProposal));
  }

  getFieldBackupState(intentId: string, telematicsCustomer: number): Observable<IFieldBackupStateResponse> {
    return this.serviceCall
      .get(`${this.migrationIntensBaseUrl}/${intentId}/field-backup/state?telematicsCustomerId=${telematicsCustomer}`)
      .pipe(map((response) => response.data as IFieldBackupStateResponse));
  }
}
